import componentInitializer from "@/frontend/utils/componentInitializer"
import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"

window.listingGuestFavourite = () => {
  const targets = document.querySelectorAll(".listing-guest-favourite")

  if (targets.length > 0) {
    const component = defineAsyncComponent(() => import("@/components/Listing/GuestFavourite.vue"))
    targets.forEach((target) => {
      const listing = JSON.parse(target.dataset.listing)
      const showText = target.dataset.showText == "true"
      const className = target.dataset.class || ""

      new Vue({
        el: target,
        store,
        render: (h) =>
          h(component, {
            props: {
              listing,
              showText,
              className,
            },
          }),
      })
    })
  }
}

componentInitializer(listingGuestFavourite)

componentInitializer(() => {
  window.guestFavouriteObserver?.disconnect()

  const targets = document.querySelectorAll("[data-watch-guest-favourite]")

  if (targets.length === 0) return

  const config = { childList: true, subtree: true }
  const observer = new MutationObserver(() => {
    listingGuestFavourite()
  })

  targets.forEach((t) => {
    observer.observe(t, config)
  })

  window.guestFavouriteObserver = observer
})
